<template>
  <div :class="styling.wrapperClass ? styling.wrapperClass : ''">
    <v-btn
      :style="customCss"
      :color="styling.color ? styling.color : 'black'"
      :disabled="disabled"
      :dark="mode !== '' ? false : styling.dark ? styling.dark : false"
      :depressed="styling.depressed ? styling.depressed : false"
      :outlined="styling.outlined ? styling.outlined : false"
      :x-large="styling.xLarge ? styling.xLarge : false"
      :large="styling.large ? styling.large : false"
      :x-small="styling.xSmall ? styling.xSmall : false"
      :small="styling.small ? styling.small : false"
      :rounded="styling.rounded ? styling.rounded : false"
      :class="styling.class ? styling.class : ''"
      :loading="loading ? loading : styling.loading ? styling.loading : false"
      :label="styling.label ? styling.label : ''"
      :type="type"
      :height="styling.height ? styling.height : ''"
      :width="styling.width ? styling.width : ''"
      :link="link"
      :right="right"
      :left="left"
      :href="href ? href : ''"
      :ripple="ripple"
      :icon="styling.icon ? styling.icon : false"
      :fab="fab"
      :target="blank ? '_blank' : ''"
      @click.prevent="$emit('click')"
    >
      <span v-if="model">{{ model }}</span>
      <span v-else>{{ styling.label }}</span>
      <v-icon
        v-if="styling.appendIcon"
        class="ml-4"
        :dense="styling.denseIcon"
        :right="styling.righIcon"
        :left="styling.leftIcon"
      >
        {{ styling.appendIcon }}
      </v-icon>
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'AButton',
  props: {
    customCss: {
      type: String,
      default: ''
    },
    badgeText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    fab: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    link: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      default: ''
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    ripple: {
      type: [String, Object, Boolean],
      default: null
    },
    styling: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    blank: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: '',
      required: false
    }
  }
};
</script>
