



































import { computed } from '@vue/composition-api';

export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    appendIcon: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    singleLine: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ''
    },
    defaultClasses: {
      type: String,
      default: ''
    },
    iconClick: {
      type: Function,
      default: () => {}
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    keyupEnter: {
      type: Function,
      default: () => {}
    },
    errorMessages: {
      type: Array,
      default: () => {}
    },
    styling: {
      type: Object,
      default: () => {}
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessagees: {
      type: Array,
      default: () => []
    },
    lowercase: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const inputValue = computed({
      get: () => props.value,
      set: newVal => {
        const valueToEmit = props.lowercase ? newVal.toLowerCase() : newVal;
        ctx.emit('input', valueToEmit);
      }
    });
    console.log(props.errorMessagees, 'errorMessagees');
    return { inputValue };
  }
};
